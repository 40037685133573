import React, { useEffect } from 'react'
import { servicesArray } from '../../dummyData';
import { Link } from 'react-router-dom';

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='md:p-20'>
        {servicesArray.map((info, id) => {
          return (
            <div className="p-3 pb-10" key={id}>
              <div className="text-2xl border-b">{info.title}</div>
              <div className="h5 pt-3">{info.content}</div>
              <ul>
                {info.listItems.map((item, i) => (
                  <li key={i} className='list-disc ml-8'>
                    {item.body}
                    <br />
                    {item.link ? (
                      <iframe
                        src={item.link}
                        title={item.link}
                        loading="lazy"
                        className="full-width"
                        style={{ height: 400 }}
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
    </div>
  )
}

export default Services