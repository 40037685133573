import React, { useEffect, useState } from "react";
import { data } from "../../dummyData";
import MessageContainer from "../../components/MessageContainer";
import Marquee from "react-fast-marquee";
import banner_1 from "../../assets/img/banner_1.jpg";
import banner_2 from "../../assets/img/banner_2.jpg";
import banner_3 from "../../assets/img/banner_3.jpg";
import careGive from "../../assets/img/caregive.jpeg";
import counselIntern from "../../assets/img/counselIntern.jpeg";
import obn1 from "../../assets/img/obn_1.jpeg";
import obn2 from "../../assets/img/obn_2.jpeg";
import obn3 from "../../assets/img/obn_3.jpeg";
import obn4 from "../../assets/img/obn_4.jpeg";
import obn5 from "../../assets/img/obn_5.jpeg";
import obn6 from "../../assets/img/obn_6.jpeg";
import obn8 from "../../assets/img/obn_8.jpeg";
import pharmacist from "../../assets/img/phamacist.jpeg";
import manithaPerera from "../../assets/img/manithaPerera.jpeg";

export default function Home() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setArticles(data);
  }, []);

  

  return (
      <main>
        <div className="box theme-bg-primary margin-minus p-0">
          <Marquee gradient={false} style={{ height: 40, fontSize: 16, }} > Free Physical Body Checkup | OPD Treatment | Specialist Channeling | Laboratory Services | Indoor Pharmacy | Emergency Treatment | Surgery & Wound care | Chronic Disease Management | Women Health Services | Medical Check up | Eye Check up | Screening & Preventive Care | Psychological Counselling Services </Marquee>
        </div>
        <div className="grid md:grid-cols-2 gap-4 pb-5 md:px-5">
          <img className="rounded-md" src={obn8} alt="obn8" />
          <img className="rounded-md" src={obn5} alt="obn7" />
        </div>
        <div className="text-center container-1">
          <Marquee>
              <img className="marquee-image-height" src={banner_1} alt="slide 1"/>
              <img className="marquee-image-height" src={banner_2} alt="slide 1"/>
              <img className="marquee-image-height" src={banner_3} alt="slide 1"/>
              <img className="marquee-image-height" src={banner_1} alt="slide 2"/>
              <img className="marquee-image-height" src={banner_2} alt="slide 3"/>
          </Marquee>
        </div>
        <div className="grid md:grid-cols-2 gap-4 py-5 md:px-5">
            <img className="rounded-md" src={pharmacist} alt="pharmacist" />
            <img className="rounded-md" src={manithaPerera} alt="manithaPerera" />
            <img className="rounded-md" src={obn6} alt="obn6" />
            <img className="rounded-md" src={obn4} alt="obn4" />
            <img className="rounded-md" src={obn3} alt="obn3" />
            <img className="rounded-md" src={obn2} alt="obn2" />
            <img className="rounded-md" src={obn1} alt="obn1" />
            <img className="rounded-md" src={counselIntern} alt="countInt"/>
            <img className="rounded-md" src={careGive} alt="care" />
        </div>

        {articles.map((article, id) => {
          return (
            <MessageContainer
              key={id}
              title={article.title}
              content={article.content}
              description={article.description}
              image={article.image}
              isImageLeft={article.isImageLeft}
            />
          );
        })}
      </main>
  );
}
