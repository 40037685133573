import { SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import processReq from '../../../helpers/processReq';
import { GET } from '../../../utils/requestActionConstants';
import { API_ROUTE } from '../../../utils/commonConstants';

function Certificate() {
    let [stuRegNum, setStuRegNum] = useState('');
    let [certificates, setCertificates] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    let [errorText, setErrorText] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const searchResults = async (event) => {
        event.preventDefault();

        if (stuRegNum) {
            setIsLoading(true);

            let data = await processReq(GET, API_ROUTE + '/certificate/student?stuId=' + stuRegNum);

            if (!data) {
                setErrorText('No certificates found!');
                setCertificates([]);
            } else {
                setCertificates(data);

                if (data.length > 0) {
                    setErrorText('');
                } else {
                    setErrorText('No certificates found!');
                    setCertificates([]);
                }
            }
        } else {
            setErrorText('Student Registeration Number Cannot Be Empty');
            setCertificates([]);
        }

        setIsLoading(false);
    }

    return (
        <div>
            <div className='py-20 md:px-20 px-5'>
                <div className='text-center'>
                    <div className='text-3xl'>Verify Your Course Completion Here!</div>
                    <form onSubmit={searchResults}>
                        <Input className='md:w-4/12 my-10' type='text' value={stuRegNum} onChange={(e) => setStuRegNum(e.target.value)} size="large" placeholder="Enter Your Student Registration Number" prefix={<SearchOutlined />} /> <br />
                    </form>
                    <Button onClick={searchResults} loading={isLoading}>
                        Submit
                    </Button>
                </div>

                <div className='py-10'>
                    {certificates.length ? <div className='pb-5 text-green-700 text-center'>Congratulations! You have successfully completed {certificates.length} course/s...</div> : ''}
                    {errorText ? <div className='pb-5 text-red-700 text-center'>{errorText}</div> : ''}
                    {certificates.map((item) => {
                        return (
                            <Card
                                title={`${item.certDisplayId} - ${item.courseName}`}
                                extra={<Badge className="site-badge-count-109" count={'Verified'} style={{ backgroundColor: '#52c41a' }}/>}
                                key={item.id}
                                className='text-left'
                            >
                                <p>Student ID: {item.studentDisplayId}</p>
                                <p>Full Name: {item.fullName}</p>
                                <p>NIC: {item.nic}</p>
                            </Card>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Certificate