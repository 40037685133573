import { configureStore } from "@reduxjs/toolkit";
import commonStateSlice from "./slices/commonStateSlice";
import counterSlice from "./slices/counterSlice";
import doctorSlice from "./slices/doctorSlice";
import tokenSlice from "./slices/tokenSlice";
import userSlice from "./slices/userSlice";

export default configureStore({
  reducer: {
    counter: counterSlice,
    commonState: commonStateSlice,
    patientToken: tokenSlice,
    doctorsDS: doctorSlice,
    userSlice: userSlice
  }
});
