import {Link} from "react-router-dom";

const RootFooter = () => {
    const onClickCall = (url) => {
        window.location.href = url;
    }

    return (
        <div className={'bg-gray-900 text-white p-10'}>
            <div className={'from-inherit align-middle'}>
                <div  className={'my-auto text-2xl md:text-3xl font-clc '}>OBERON HEALTH CARE</div>
                <div  className={'pt-10'}>No. 33, Piyadasa Sirisena Mawatha, Colombo - 10</div>
                <div className="cursor-pointer pt-4" onClick={() => (window.location.href="https://www.facebook.com/OberonHealthCare")}>Facebook</div>
                <Link className="cursor-pointer" to={'/about'}>Location</Link>
                <div>
                    <button onClick={() => onClickCall('tel:94766578787')}>Mobile: +94 766 57 87 87</button><br />
                    <button onClick={() => onClickCall('tel:94112663522')}>Phone: 0112663522</button>
                </div>
                <div  className={'pt-10'}>© 2023 Oberon Health Care </div>
            </div>
        </div>
    )
}

export default RootFooter;