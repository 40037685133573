import { Button, Form, Input, Select } from "antd";

const ReceptionHome = () => {
    const handleChange = (data) => {
        console.log(data)
    }

    return (
        <div className="flex" style={{width: '100vw', height: '100vh', maxHeight: '100vh'}}>
            <div className="grow" style={{backgroundColor: ''}}>
                <div className="m-auto" style={{width: '60%'}}>
                    <h1 className="py-5">PATIENT REGISTRATION</h1>
                    <Form onFinish={(data) => console.log(data)}>
                        
                        <Form.Item name={'telephone'} label="Telephone" rules={[{required: true, message: 'Please input a mobile number!'}]}><Input placeholder="Telephone" /></Form.Item>
                        <Input className="mb-5" placeholder="Patient Name" />
                        <Input className="mb-5" placeholder="Age" type="number" />
                        <Input className="mb-5" placeholder="Address" />
                        <Input className="mb-5" placeholder="NIC" />
                        <Select
                            defaultValue="male"
                            style={{
                                width: '100%',
                            }}
                            className="mb-5"
                            onChange={handleChange}
                            options={[
                                {
                                value: 'male',
                                label: 'male',
                                },
                                {
                                value: 'female',
                                label: 'female',
                                }
                            ]}
                            />
                        <Button htmlType="submit">REGISTER</Button>   
                    </Form>
                </div>
            </div>
            <div className="grow">
                
            </div>
        </div>
    )
}

export default ReceptionHome;