import useComponentVisible from "../hooks/useComponentVisible";
import {Link} from "react-router-dom";
import logo from '../assets/img/logo.webp';

const RootHeader = () => {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);

    return (
        <div ref={ref} className={'sticky top-0 z-50 font-semibold text-sm text-slate-900 ring-1 ring-slate-900/10'}>
                <div className={'flex bg-gradient-to-r bg-slate-50/90 backdrop-blur-sm  shadow-md shadow-slate-200 p-3'}>
                    <div className={'from-inherit align-middle'}>
                        <div className='grid grid-cols-3'>
                            <Link to={"/"}  className={'flex'}><img src={logo} width={100} alt={'OBN'}/></Link>
                            <Link to={"https://www.echannelling.com/search-results?hospital=H532~channel-Oberon-Health-Care-Colombo-10-doctors"} className={'py-2 px-3 bg-purple-900 text-white ml-2 font-semibold rounded-md whitespace-nowrap shadow-lg shadow-blue-500/50 focus:outline-none m-auto overflow-ellipsis text-xs'}>E-Channeling</Link>
                            <Link to={"/academy/certificates"} className={'py-2 px-3 bg-pink-700 text-white text-xs font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none m-auto'}>Institute</Link>
                        </div>
                    </div>
                    <div className={'grow'}></div>
                    <div className={'hidden md:flex'}>
                        <nav className="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200">
                            <ul className="flex space-x-8 px-8 h-full">
                                <li className={'my-auto primary-fore-color'}><Link to={"/"}>Home</Link></li>
                                <li className={'my-auto primary-fore-color'}><Link to={"/services"}>Services</Link></li>
                                <li className={'my-auto primary-fore-color'}><Link to={"/about"}>About</Link></li>
                                <li className={'my-auto primary-fore-color'}><Link to={"/psychology"}>Psychology Unit</Link></li>
                                <li className={'my-auto primary-fore-color'}><Link to={"/reception"}>Login</Link></li>
                            </ul>
                        </nav>
                    </div>
                    {!isComponentVisible ? (
                        <div className={'flex md:hidden animate-fadeIn pr-4'}>
                            <div className={'icon-bars my-auto'} onClick={() => setIsComponentVisible(true)}></div>
                        </div>
                    ) : (
                        <div className={'flex md:hidden animate-fadeIn pr-4'}>
                            <div className={'my-auto'} onClick={() => setIsComponentVisible(false)}>X</div>
                        </div>
                    )}
                </div>
                <div className={(isComponentVisible ? 'animate-slide' : 'animate-slideOut hidden') + ' absolute top-20 z-50 right-0 bg-slate-50 w-full'}>

                    <nav className="px-3 py-3 text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200">
                        <ul className="h-full w-full px-3 py-3" onClick={() => setIsComponentVisible(false)}>
                            <li className={'my-auto primary-fore-color'}><Link to={"/"}>Home</Link></li>
                            <li className={'my-auto primary-fore-color'}><Link to={"/services"}>Services</Link></li>
                            <li className={'my-auto primary-fore-color'}><Link to={"/about"}>About</Link></li>
                            <li className={'my-auto primary-fore-color'}><Link to={"/psychology"}>Psychology Unit</Link></li> 
                            <li className={'my-auto primary-fore-color'}><Link to={"/reception"}>Login</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
    )
}

export default RootHeader;