import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/slices/userSlice";
import { API_ROUTE } from "../utils/commonConstants";
import { POST } from "../utils/requestActionConstants";
import processReq from "../helpers/processReq";

const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const user = useSelector(state => state.userSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        let token = sessionStorage.getItem('token');

        if ((token && token.length > 0)) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [user])

    const logOut = () => {
        dispatch(setUser({user: null}));
        sessionStorage.clear();
    }

    const login = async (username, password) => {
        const response = await processReq(POST, API_ROUTE + '/login', {username, password});

        if (response.error) {
            console.log('Processing failed!', response.message);
            sessionStorage.clear();

            return response.message;
        } else {
            dispatch(setUser({token: response.token}));
            sessionStorage.setItem('token', response.token);
        }
    }

    return {isLoggedIn, logOut, login};
}

export default useAuth;