import { createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { API_ROUTE } from "../../utils/commonConstants";
import {
  ADD_NEW,
  GET_MULTIPLE_BY_SERVICE_ID,
  GET_SINGLE,
  GET_SINGLE_BY_SERVICE_ID,
} from "../../utils/requestActionConstants";
import { setGlobalLoading } from "./commonStateSlice";

const init = {
  patientId: "not defined",
  telephone: "",
  fullName: "",
  age: "",
  address: "",
  nic: "",
  gender: "",
  token: "",
  obnId: "",
  receipts: [{}],
  currentReceipt: {},
  services: [],
  grandTotal: 0,
  lastSaved: "",
  status: "new",
  loading: false,
};

export const tokenSlice = createSlice({
  name: "patientToken",
  initialState: init,
  reducers: {
    setTelephone: (state, action) => {
      state.telephone = action.payload;
    },

    setFullName: (state, action) => {
      state.fullName = action.payload;
    },

    setAge: (state, action) => {
      state.age = action.payload;
    },

    setAddress: (state, action) => {
      state.address = action.payload;
    },

    setNic: (state, action) => {
      state.nic = action.payload;
    },

    setGender: (state, action) => {
      state.gender = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },

    setObnId: (state, action) => {
      state.obnId = action.payload;
    },

    addService: (state, action) => {
      state.services = [...state.services, action.payload];
    },

    updateService: (state, action) => {
      let services = state.services;
      let data = action.payload;
      let selectedIndex = services.findIndex(
        (service) => service.service === data.service
      );

      services[selectedIndex] = data;
      state.services = services;
    },

    deleteService: (state, action) => {
      let services = state.services;
      let deleteRequestService = action.payload;
      let selectedIndex = services.findIndex(
        (service) => service.service === deleteRequestService.service
      );

      services.splice(selectedIndex, 1);
      state.services = services;
    },

    calculateGrandTotal: (state) => {
      let tot = 0;

      for (let service of state.services) {
        let labServiceItemsTotal = 0;
        if (service.labServiceItems) {
          for (let item of service.labServiceItems) {
            labServiceItemsTotal += isNaN(item.serviceFee)
              ? 0
              : parseFloat(item.serviceFee);
          }
        }

        let pharmacyServiceItemsTotal = 0;
        if (service.pharmacyServiceDrugs) {
          for (let item of service.pharmacyServiceDrugs) {
            pharmacyServiceItemsTotal += isNaN(item.amount)
              ? 0
              : parseFloat(item.amount);
          }
        }

        let procedureServiceItemsTotal = 0;
        if (service.procedureServiceProcedures) {
          for (let item of service.procedureServiceProcedures) {
            procedureServiceItemsTotal += isNaN(item.amount)
              ? 0
              : parseFloat(item.amount) + parseFloat(item.doctorFee);
          }
        }

        let scanServiceScanItemsTotal = 0;
        if (service.scanServiceScans) {
          for (let item of service.scanServiceScans) {
            scanServiceScanItemsTotal += isNaN(item.scanAmount)
              ? 0
              : parseFloat(item.scanAmount) + parseFloat(item.doctorFee);
          }
        }

        let otherServiceTotal = 0;
        if (service.otherServiceServices) {
          for (let item of service.otherServiceServices) {
            otherServiceTotal += isNaN(item.amount)
              ? 0
              : parseFloat(item.amount);
          }
        }

        tot += otherServiceTotal;
        tot += scanServiceScanItemsTotal;
        tot += procedureServiceItemsTotal;
        tot += pharmacyServiceItemsTotal;
        tot += labServiceItemsTotal;
        tot += isNaN(service.doctorFee) ? 0 : parseInt(service.doctorFee);
        tot += isNaN(service.hospitalFee) ? 0 : parseInt(service.hospitalFee);
      }

      state.grandTotal = tot;
    },

    setLastSavedTime: (state) => {
      state.lastSaved = moment().format("LLL");
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setTokenData: (state, action) => {
      return action.payload;
    },

    clearData: (state) => {
      return init;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setTelephone,
  setFullName,
  setAge,
  setAddress,
  setGender,
  setNic,
  setToken,
  addService,
  calculateGrandTotal,
  clearData,
  updateService,
  setObnId,
  setLastSavedTime,
  setTokenData,
  setStatus,
  setLoading,
  deleteService,
} = tokenSlice.actions;

export const saveTokenToDB = (data) => async (dispatch) => {
  dispatch(setGlobalLoading(true));
  try {
    const res = await axios.post(API_ROUTE + "/token", {
      action: ADD_NEW,
      tokenData: data,
    });

    let tokenData = res.data.data.response;
    let servicesArray = [];
    let selectedService = tokenData;

    if (selectedService.opd) {
      const doctorData = await axios.post(API_ROUTE + "/doctor", {
        action: GET_SINGLE,
        id: selectedService.opd.doctorId,
      });

      let doctor = doctorData.data.data;

      servicesArray.push({
        service: "opd",
        ...selectedService.opd,
        doctorLabel: doctor.label,
        doctor: doctor,
      });
    }

    if (selectedService.consultation) {
      const doctorData = await axios.post(API_ROUTE + "/doctor", {
        action: GET_SINGLE,
        id: selectedService.consultation.doctorId,
      });

      let doctor = doctorData.data.data;

      servicesArray.push({
        service: "consultation",
        ...selectedService.consultation,
        doctorLabel: doctor.label,
        doctor: doctor,
      });
    }

    if (selectedService.labService) {
      servicesArray.push(selectedService.labService);
    }

    if (selectedService.pharmacyService) {
      servicesArray.push(selectedService.pharmacyService);
    }

    if (selectedService.procedureService) {
      servicesArray.push(selectedService.procedureService);
    }

    if (selectedService.scanService) {
      servicesArray.push(selectedService.scanService);
    }

    if (selectedService.homeVisitService) {
      servicesArray.push(selectedService.homeVisitService);
    }

    if (selectedService.otherService) {
      servicesArray.push(selectedService.otherService);
    }

    tokenData = {
      ...tokenData,
      services: servicesArray,
      serviceId: selectedService.serviceId,
      status: "edit",
      lastSaved: moment(selectedService.updatedAt).format("LLL"),
      telephone: tokenData.patient.telephone,
      fullName: tokenData.patient.fullName,
      age: tokenData.patient.age,
      address: tokenData.patient.address,
      nic: tokenData.patient.nic,
      gender: tokenData.patient.gender,
      obnId: tokenData.patient.obnId,
    };

    // --------- IMPORTANT HANDLE THIS IF ANY ISSUE THROW -----------

    // dispatch(clearData());

    // --------- IMPORTANT HANDLE THIS IF ANY ISSUE THROW -----------

    dispatch(setTokenData(tokenData));

    dispatch(setGlobalLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setGlobalLoading(false));
  }
};

export const reloadTokenData = (patientId, args) => async (dispatch) => {
  if (!args.hideLoadingBackdrop) {
    dispatch(setGlobalLoading(true));
  } else {
    dispatch(setLoading(true));
  }

  const tokenData = await axios.post(API_ROUTE + "/patient", {
    action: GET_SINGLE,
    id: patientId,
  });

  // all services listed here (service history). Getting the current service details also.
  let patientsServices = tokenData.data.data.services;
  let services = patientsServices.filter(
    (service) => service.id === args.serviceId
  );

  let currentServicesArray = [];
  let receiptId;
  let lastSaved;
  let serviceId;

  // setting services
  for (let s of services) {
    if (s.opd) {
      const doc = await axios.post(API_ROUTE + "/doctor", {
        action: GET_SINGLE,
        id: s.opd.doctorId,
      });

      receiptId = s.opd.receiptId;
      // console.log("receipt id is", receiptId);
      lastSaved = moment(s.opd.updatedAt).format("LLL");
      serviceId = s.opd.serviceId;

      currentServicesArray.push({
        service: "opd",
        ...s.opd,
        doctorLabel: doc.data.data.label,
        doctor: doc.data.data,
      });
    }

    if (s.consultation) {
      const doc = await axios.post(API_ROUTE + "/doctor", {
        action: GET_SINGLE,
        id: s.consultation.doctorId,
      });

      receiptId = s.consultation.receiptId;
      lastSaved = moment(s.consultation.updatedAt).format("LLL");
      serviceId = s.consultation.serviceId;

      currentServicesArray.push({
        service: "consultation",
        ...s.consultation,
        doctorLabel: doc.data.data.label,
        doctor: doc.data.data,
      });
    }

    if (s.labService) {
      receiptId = s.labService.receiptId;
      lastSaved = moment(s.labService.updatedAt).format("LLL");
      serviceId = s.labService.serviceId;

      currentServicesArray.push(s.labService);
    }

    if (s.pharmacyService) {
      receiptId = s.pharmacyService.receiptId;
      lastSaved = moment(s.pharmacyService.updatedAt).format("LLL");
      serviceId = s.pharmacyService.serviceId;

      currentServicesArray.push(s.pharmacyService);
    }

    if (s.procedureService) {
      receiptId = s.procedureService.receiptId;
      lastSaved = moment(s.procedureService.updatedAt).format("LLL");
      serviceId = s.procedureService.serviceId;

      currentServicesArray.push(s.procedureService);
    }

    if (s.scanService) {
      receiptId = s.scanService.receiptId;
      lastSaved = moment(s.scanService.updatedAt).format("LLL");
      serviceId = s.scanService.serviceId;

      currentServicesArray.push(s.scanService)
    }

    if (s.homeVisitService) {
      receiptId = s.homeVisitService.receiptId;
      lastSaved = moment(s.homeVisitService.updatedAt).format("LLL");
      serviceId = s.homeVisitService.serviceId;

      currentServicesArray.push(s.homeVisitService)
    }

    if (s.otherService) {
      receiptId = s.otherService.receiptId;
      lastSaved = moment(s.otherService.updatedAt).format("LLL");
      serviceId = s.otherService.serviceId;

      currentServicesArray.push(s.otherService)
    }
  }

  let latestData = {};

  if (receiptId || (args.deleted && args.token.currentReceipt)) {
    // setting Current Recipt
    const currentReceipt = await axios.post(API_ROUTE + "/receipt", {
      action: GET_SINGLE,
      id: args.deleted ? args.token.currentReceipt.id : receiptId,
    });

    latestData = {
      ...tokenData.data.data,
      currentReceipt: currentReceipt.data.data,
      patientId: patientId,
      services: currentServicesArray,
      status: "edit",
      lastSaved: lastSaved,
      serviceId: serviceId || args.serviceId,
      forwardingRoute: args.forwardRoute ? args.forwardRoute : undefined,
    };
  } else {
    latestData = init;
  }

  delete tokenData.data.data.services;

  // console.log("current services array", currentServicesArray);

  dispatch(setTokenData(latestData));

  dispatch(setGlobalLoading(false));
  dispatch(setLoading(false));
  // console.log("printing the token info in refresh section", latestData);
};

export default tokenSlice.reducer;
