import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "./pages/Root";
import Home from "./pages/home/Home";
import { Provider } from 'react-redux';
import store from './store/index';
import Services from './pages/services/Services';
import About from './pages/about/About';
import Academy from './pages/academy/Academy';
import Certificate from './pages/academy/certificate/Certificate';
import AdminRoot from './pages/admin/AdminRoot';
import Doctors from './pages/doctors/Doctors';
import NotFound from './pages/404';
import { ConfigProvider } from 'antd';
import ReceptionRoot from './pages/reception/ReceptionRoot';
import ReceptionHome from './pages/reception/ReceptionHome';
import History from './pages/reception/history/History';
import Token from './pages/reception/token/Token';
import Psychology from './pages/psychology/Psychology';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
        {
            path:"/",
            element: <Home />
        },
        {
          path: '/services',
          element: <Services />
        },
        {
          path: '/about',
          element: <About />
        },
        {
          path: '/academy/certificates',
          element: <Certificate />
        },
        {
          path: '/psychology',
          element: <Psychology />
        },
    ]
  },
  {
    path: '/reception',
    element: <ReceptionRoot />,
    errorElement: <div>404! Admin Route Not Found!</div>,
    children: [
      {
        path: '',
        element: <ReceptionHome />
      },
      {
        path: 'history',
        element: <History />
      },
      {
        path: 'token',
        element: <Token />
      }
    ]
  },
  {
    path: '/admin',
    element: <AdminRoot />,
    errorElement: <div>404! Admin Route Not Found!</div>,
    children: [
      {
        path: '',
        element: <div style={{height: '100%'}}>Admin</div>
      },
      {
        path: 'doctors',
        element: <Doctors />
      }
    ]
  },
  {
    path: '/institute',
    element: <Academy />,
    errorElement: <div>404! Institute Route Not Found!</div>,
    children: []
  }
]);

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
          <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: 'rgb(132, 1, 132)',

              // Alias Token
              // colorBgContainer: '#f6ffed',
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
