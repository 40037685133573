import moment from "moment";

export const data = [
  {
    title: "Primary Health Care",
    content:
      "Our primary care incorporates several different kinds of healthcare services. To keep you healthy and free of disease from head to toe",
    description:
      "When it comes to primary care, our specialist attention forces on delivering a comprehensive management with promotion and treatments. We also forces on medical, surgical, paediatric, gynaecological and obstetrics with other subspeciality care to committed to improve the life of human being in Sri Lanka. We vale the quality of life of the patience, special attention with those who have chronic disease such as Diabetic, Heard disease, respiratory disease and women's health. Our doctors are trained to prevent, diagnose, and treat conditions in people of all ages, including children and older adults. They can perform a wide range of services from disease diagnosis to minor surgery.",
    image: "/landingpage/opd.jpeg",
    isImageLeft: true,
  },
  {
    title: "Gynocological and Obstetic Care",
    content:
      "Pregnancy and childbirth are the life changing experience, we understand the special care during this wonderful time.",
    description:
      "We have the facilities to treat the gynocological and obstetic condition with all basic resousess. Having a Well experience obstetric and gynocologist in our team is a huge streanth to the OBERON team. We want to establish a multideciplinary team to improve the womens health and we strive to provide comprahencive care in womens’ health.",
    image: "/landingpage/consult.jpeg",
    isImageLeft: false,
  },
  {
    title: "Eye care",
    content:
      "The most important sense among all the senses is the sense of sight. Healthy eyesight is essential to live the happy and safe life.",
    description:
      "We believe that good quality eye care services should be available to everyone. To take care of your eyesight and to ensure the quality eye care services, OBERON health care, We also provide an essential eye care services with well equipped eye care centre managed by well experience and competent team.",
    image: "/landingpage/women.jpeg",
    isImageLeft: true,
  },
  {
    title: "Psychological care",
    content: "Mental health is a key part of your overall health.",
    description:
      "Our psychological team do mental health screening and counselling services to children, young people, adults, and older people. Our counselling psychologists help people with physical, emotional, and mental health issues improve their sense of well‐being, alleviate feelings of distress, and resolve crises.",
    image: "/landingpage/stress.jpeg",
    isImageLeft: false,
  },
  {
    title: "Research and Training unit",
    content:
      "Oberon research and training unit provide the services to improve the medical practice by conducting and facilitating the research and training of health staffs in both preventive and curative health care.",
    description:
      "We support the ongoing education and training of researchers by providing a range of education and training options. We facilitate the health care staff to conduct the research in all levels.",
    image: "/landingpage/blood.jpeg",
    isImageLeft: true,
  },
];

export const patients = [
  {
    id: 1,
    obn_id: "OBN_123",
    fullName: "Thanish",
    telephone: "1234",
    age: 32,
    address: "COLOMBO",
    nic: "12345678",
    gender: "MALE",
  },
  {
    id: 2,
    obn_id: "OBN_124",
    fullName: "Thanish",
    telephone: "1234",
    age: 32,
    address: "COLOMBO",
    nic: "12345678",
    gender: "FEMALE",
  },
  {
    id: 3,
    obn_id: "OBN_125",
    fullName: "Thanish",
    telephone: "12",
    age: 32,
    address: "COLOMBO",
    nic: "12345678",
    gender: "MALE",
  },
];

// export const doctors = [
//   {
//     id: 1,
//     name: "Doctor Jhon",
//     label: "Doctor John",
//     tel: "1234",
//     email: "john@gmail.com",
//     visitingDate: moment().format("YYYY-DD-MM"),
//     doctorFee: 1500,
//     treatments: [
//       { id: 3, date: moment().format("YYYY-DD-MM"), token: 34 },
//       { id: 4, date: moment().format("YYYY-DD-MM"), token: 35 },
//       { id: 5, date: moment().format("YYYY-DD-MM"), token: 37 },
//       { id: 6, date: moment().format("YYYY-DD-MM"), token: 53 },
//       { id: 7, date: moment().format("YYYY-DD-MM"), token: 33 },
//       { id: 8, date: moment().format("YYYY-DD-MM"), token: 12 },
//     ],
//   },
//   {
//     id: 2,
//     name: "Doctor Peter",
//     label: "Doctor Peter",
//     tel: "2468",
//     email: "peter@gmail.com",
//     visitingDate: moment().format("YYYY-DD-MM"),
//     doctorFee: 1000,
//     treatments: [
//       { id: 1, date: moment().format("YYYY-DD-MM"), token: 15 },
//       { id: 2, date: moment().format("YYYY-DD-MM"), token: 16 },
//       { id: 5, date: moment().format("YYYY-DD-MM"), token: 13 },
//       { id: 6, date: moment().format("YYYY-DD-MM"), token: 39 },
//     ],
//   },
// ];

export const doctors = [];

export const aboutPageInfo = [
  {
    title: "VISION",
    content:
      "To be the centre of excellence to ensure quality health promotion and care for everyone through transformative innovation and research.",
    listItems: [],
  },
  {
    title: "MISSION",
    content:
      "To bring healthcare of maximum quality to be access and affordable to every individual. We are devoted to the achievement and maintenance of excellence in health promotion, research and healthcare for the benefit of humanity.",
    listItems: [],
  },
  {
    title: "POLICY",
    content: "We commited to,",
    listItems: [
      { id: 1, body: "Practice safe and ethical medicine" },
      {
        id: 2,
        body: "Deploy the best of medical technology and continuous training for improvement",
      },
      {
        id: 3,
        body: "Ensure transparency and accountability in healthcare delivery and billing",
      },
      {
        id: 4,
        body: "Provide employee satisfaction and a cordial working atmosphere.",
      },
    ],
  },
  {
    title: "OUR VALUES",
    content: "",
    listItems: [
      {
        id: 5,
        body: "EXCELLENCE",
      },
      {
        id: 6,
        body: "EMPATHY ",
      },
      {
        id: 7,
        body: "ETHICS ",
      },
    ],
  },
  {
    title: "WE ARE AT",
    content: "",
    listItems: [
      {
        id: 5,
        body: "We located at easily accessible location in the heart of the health care in Sri Lanka",
      },
      {
        id: 6,
        body: "No 33 Piyadasa Sirisena Mawatha , Colombo 10",
        link: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8548.944274272713!2d79.86243208524048!3d6.929416793720698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2590c359cd6c1%3A0x8bbb88ab07f7135!2s33%20Piyadasa%20Sirisena%20Mawatha%2C%20Colombo%2001000!5e0!3m2!1sen!2slk!4v1661672697727!5m2!1sen!2slk",
      },
    ],
  },
  {
    title: "Our Resources",
    content: "",
    listItems: [
      {
        id: 1,
        body: "We utilized the standard technology and equipment's in health care provisions.",
      },
      {
        id: 2,
        body: "We have adequate facilities for a quality and optimum service to make our patients feel confutable and happy",
      },
      {
        id: 3,
        body: "We have a dedicated team of staffs to provide a maximum and standard care through a shared care with other private and government institutions.",
      },
      {
        id: 4,
        body: "Our staffs are qualified, trained and well experienced in their respective fields",
      },
      {
        id: 5,
        body: "Remarkable sensitivity to patient’s needs makes our commitment sterned",
      },
      {
        id: 6,
        body: "Continuous education and training makes us in excellence in both preventive and curative health care",
      },
    ],
  },
  {
    title: "Gynocological and Obstetic Care",
    content: "",
    listItems: [
      {
        id: 1,
        body: "Pregnancy and childbirth are the life changing experience, we understand the special care during this wonderful time.",
      },
      {
        id: 1,
        body: "We have the facilities to treat the gynocological and obstetic condition with all basic resousess. Having a Well experience obstetric and gynocologist in our team is a huge streanth to the OBERON team.",
      },
      {
        id: 1,
        body: "We want to establish a multideciplinary team to improve the womens health and we strive to provide comprahencive care in womens’ health.",
      },
    ],
  },
  {
    title: "Eye care",
    content: "",
    listItems: [
      {
        id: 1,
        body: "The most important sense among all the senses is the sense of sight. Healthy eyesight is essential to live the happy and safe life.",
      },
      {
        id: 1,
        body: "We believe that good quality eye care services should be available to everyone.",
      },
      {
        id: 1,
        body: "To take care of your eyesight and to ensure the quality eye care services, OBERON health care, We also provide an essential eye care services with well equipped eye care centre managed by well experience and competent team.",
      },
    ],
  },

  {
    title: "Psychological care",
    content: "",
    listItems: [
      { id: 1, body: "Mental health is a key part of your overall health." },
      {
        id: 2,
        body: "Our psychological team do mental health screening and counselling services to children, young people, adults, and older people",
      },
      {
        id: 3,
        body: "Our counselling psychologists help people with physical, emotional, and mental health issues improve their sense of well‐being, alleviate feelings of distress, and resolve crises",
      },
    ],
  },

  {
    title: "Research and Training unit",
    content: "",
    listItems: [
      {
        id: 1,
        body: "Oberon research and training unit provide the services to improve the medical practice by conducting and facilitating the research and training of health staffs in both preventive and curative health care.",
      },
      {
        id: 2,
        body: "We support the ongoing education and training of researchers by providing a range of education and training options.",
      },
      {
        id: 3,
        body: "We facilitate the health care staff to conduct the research in all levels.",
      },
    ],
  },

  {
    title: "",
    content:
      "We are committed to feel you like being at your home, because, we know, if you feel better, you will heal better…. Welcome to Oberon Health Care the place with hope, happy and good health We provide standard quality health care with affordable cost. WE CARE YOUR HELATH!",
    listItems: [],
  },
];

export const servicesArray = [
  {
    title: "Our Services",
    content: "",
    listItems: [
      { id: 1, body: "OPD Treatment" },
      { id: 2, body: "Specialist channelling " },
      { id: 3, body: "Laboratory services" },
      { id: 4, body: "Indoor Pharmacy " },
      { id: 5, body: "Emergency treatment " },
      { id: 6, body: "Surgery & Wound care" },
      { id: 7, body: "Chronic disease management " },
      { id: 8, body: "Women health services " },
      { id: 9, body: "Medical check up" },
      { id: 10, body: "Eye check up " },
      { id: 11, body: "Screening & Preventive care " },
      { id: 12, body: "psychological Counselling services" },
      // {
      //   id: 13,
      //   body: "Safety of the patients is a prime concern for us. We aim to ensure that the patients have a smooth and uneventful recovery from her/his problem and go back with smile face",
      // },
      // {
      //   id: 14,
      //   body: "60% of the Sri Lankan people are spending from their own pocket for getting health care. So affordability to get comprehensive care is a huge challenge to achieve the universal health coverage",
      // },
      // { id: 15, body: "We treat every one with affordable cost" },
      // {
      //   id: 16,
      //   body: "We want to be the centre of excellence in providing trusted affordable quality health care to all Sri Lankan.",
      // },
    ],
  },
  {
    title: "Primary Health Care",
    content: "",
    listItems: [
      {
        id: 1,
        body: "Our primary care incorporates several different kinds of healthcare services. To keep you healthy and free of disease from head to toe",
      },
      {
        id: 2,
        body: "When it comes to primary care, our specialist attention forces on delivering a comprehensive management with promotion and treatments.",
      },
      {
        id: 3,
        body: "We also forces on medical, surgical, paediatric, gynaecological and obstetrics with other subspeciality care to committed to improve the life of human being in Sri Lanka.",
      },
      {
        id: 4,
        body: "We vale the quality of life of the patience, special attention with those who have chronic disease such as Diabetic, Heard disease, respiratory disease and women's health.",
      },
      {
        id: 5,
        body: "Our doctors are trained to prevent, diagnose, and treat conditions in people of all ages, including children and older adults. They can perform a wide range of services from disease diagnosis to minor surgery.",
      },
    ],
  },
  {
    title: "Gynocological and Obstetic Care",
    content: "",
    listItems: [
      {
        id: 1,
        body: "Pregnancy and childbirth are the life changing experience, we understand the special care during this wonderful time.",
      },
      {
        id: 1,
        body: "We have the facilities to treat the gynocological and obstetic condition with all basic resousess. Having a Well experience obstetric and gynocologist in our team is a huge streanth to the OBERON team.",
      },
      {
        id: 1,
        body: "We want to establish a multideciplinary team to improve the womens health and we strive to provide comprahencive care in womens’ health.",
      },
    ],
  },
  {
    title: "Eye care",
    content: "",
    listItems: [
      {
        id: 1,
        body: "The most important sense among all the senses is the sense of sight. Healthy eyesight is essential to live the happy and safe life.",
      },
      {
        id: 1,
        body: "We believe that good quality eye care services should be available to everyone.",
      },
      {
        id: 1,
        body: "To take care of your eyesight and to ensure the quality eye care services, OBERON health care, We also provide an essential eye care services with well equipped eye care centre managed by well experience and competent team.",
      },
    ],
  },

  {
    title: "Psychological care",
    content: "",
    listItems: [
      { id: 1, body: "Mental health is a key part of your overall health." },
      {
        id: 2,
        body: "Our psychological team do mental health screening and counselling services to children, young people, adults, and older people",
      },
      {
        id: 3,
        body: "Our counselling psychologists help people with physical, emotional, and mental health issues improve their sense of well‐being, alleviate feelings of distress, and resolve crises",
      },
    ],
  },

  {
    title: "Research and Training unit",
    content: "",
    listItems: [
      {
        id: 1,
        body: "Oberon research and training unit provide the services to improve the medical practice by conducting and facilitating the research and training of health staffs in both preventive and curative health care.",
      },
      {
        id: 2,
        body: "We support the ongoing education and training of researchers by providing a range of education and training options.",
      },
      {
        id: 3,
        body: "We facilitate the health care staff to conduct the research in all levels.",
      },
    ],
  },

  {
    title: "",
    content:
      "We are committed to feel you like being at your home, because, we know, if you feel better, you will heal better…. Welcome to Oberon Health Care the place with hope, happy and good health We provide standard quality health care with affordable cost. WE CARE YOUR HELATH!",
    listItems: [],
  },
];

export const psycologyUnit = {
  heading: 'MENTOUR PSYCHOLOGY PLACEMENT PROGRAM',
  subHeading: "Empowering Tomorrow's Counsellors through Practical Training",
  overview: "Mentour Psychology Placement Program is a pioneering initiative designed to bridge the gap between academic knowledge and real-world application for undergraduate students pursuing psychology. Established in 2022, our program is committed to providing aspiring counsellors with practical skills, and a deeper understanding of the diverse facets of psychology.",
  missionStatement: `"At Mentour, we strive to cultivate the next generation of compassionate and competent counsellors by offering immersive placement programs that blend theoretical knowledge with practical experience. Our mission is to empower students to apply their academic learning in real-world settings, fostering professional growth and preparing them for successful careers in the field of psychology."`,
  hightlights: [
    {
      id: 1,
      title: '1.	Industry-Experienced Mentors',
      description: `Students guided by the mentors throughout the placement. This ensures that participants receive guidance, support, and invaluable insights from experts in their chosen field. `
    },
    {
      id: 2,
      title: '2.	Diverse Placement Settings',
      description: `Mentour collaborates with a wide range of institutions, including hospitals, clinics, educational institutions, NGOs, and corporate organizations. This diversity enables students to gain exposure to various psychological domains and applications.`
    },
    {
      id: 3,
      title: '3.	Professional Networking',
      description: `Networking is a crucial aspect of career development. Our placement program provides students with opportunities to connect with professionals.`
    },
    {
      id: 4,
      title: '4.	Holistic Development',
      description: `Beyond technical skills, Mentour places emphasis on the development of soft skills such as communication, empathy, cultural competence, teamwork, leadership, personal development, event management, reflective practice, and ethical conduct. Our goal is to shape well-rounded professionals capable of making meaningful contributions to the field.`
    },
    {
      id: 5,
      title: '5.	Confidence Building',
      description: `Through exposure to diverse and challenging situations, students build confidence in their abilities and gain a deeper understanding of their own strengths and areas for growth.`
    }
  ],
  contactInfo: {
   address1: '1st floor, Oberon Psychology Unit,',
   name: 'Oberon Health Care',
   address2: '33, Piyadasa Sirisena Mawatha Colombo 10',
   email: 'hello.mentour@oberon.lk',
   website: 'oberon.lk'
  },
  footer: 'Join Mentour Psychology Placement Program and embark on a journey of personal and professional growth in the dynamic field of psychology!'
}