import React, { useState } from 'react';
import mainImage from '../../assets/img/psychology/main.jpeg';
import image1 from '../../assets/img/psychology/image1.jpeg';
import image2 from '../../assets/img/psychology/image2.jpeg';

import { psycologyUnit } from '../../dummyData';

const Psychology = () => { 
    return (
        <div className='md:p-20'>
            <div className='flex flex-col md:flex-row'>
                <div className='floating-container shadow-xl rounded-xl overflow-hidden mb-10 bg-transparent h-fit'>
                    <img src={mainImage} alt='main'/>
                </div>
                <div className='px-10'>
                    <div className='md:text-6xl text-5xl uppercase'>Psychology Unit</div>

                    <div className='pt-10'>
                        <div className='text-2xl'>{psycologyUnit.heading}</div>
                        <div className='text-l font-bold'>{psycologyUnit.subHeading}</div>
                        <div className='text-l font-bold w-fit bg-purple-400 px-2 py-1 rounded-md mt-5'>Overview</div>
                        <div className='pt-5 text-justify'>{psycologyUnit.overview}</div>
                        <div className='text-l font-bold w-fit bg-purple-400 px-2 py-1 rounded-md mt-5'>Mission Statement</div>
                        <div className='pt-5 text-justify'>{psycologyUnit.missionStatement}</div>
                        <div className='text-l font-bold w-fit bg-purple-400 px-2 py-1 rounded-md mt-5'>Program Highlights</div>
                        <div className='pt-5 text-justify'>
                            {psycologyUnit.hightlights.map(item => {
                                return (
                                    <div key={item.id}>
                                        <div className='font-bold'>{item.title}</div>
                                        <div className='pl-10 pt-2 pb-5'>{item.description}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='text-l font-bold w-fit bg-purple-400 px-2 py-1 rounded-md mt-5'>Contact Information</div>
                        <div className='pt-5'>{psycologyUnit.contactInfo.address1}</div>
                        <div>{psycologyUnit.contactInfo.name}</div>
                        <div>{psycologyUnit.contactInfo.address2}</div>
                        <div>{psycologyUnit.contactInfo.email}</div>
                        <div>{psycologyUnit.contactInfo.website}</div>
                        <div className='text-xl w-fit bg-blue-200 px-5 py-3 rounded-md mt-10 text-justify italic'>{psycologyUnit.footer}</div>
                    </div>
                </div>
                <div className='floating-container shadow-xl rounded-xl overflow-hidden mb-10 bg-transparent h-fit'>
                    <img src={image2} alt='main'/>
                    <img src={image1} alt='main1'/>
                </div>
            </div>
        </div>
    )
}

export default Psychology;