import { Button, Menu } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import Login from '../login/Login'
import logo from '../../assets/img/logo.webp';
import { HomeFilled, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ProfileFilled, ReconciliationFilled } from '@ant-design/icons'

function AdminRoot() {
  const navigate = useNavigate();
  const {isLoggedIn, logOut} = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [defaultKey, setDefaultKey] = useState('1');
  const navRoutes = {
    home: {key: '1', url: '/admin'},
    doctors: {key: '2', url: '/admin/doctors'},
    reception: {key: '3', url: '/reception'},
    logout: {key: '4', url: ''}
  }
  const navs = [
    {
      key: navRoutes.home.key,
      icon: <HomeFilled />,
      label: 'Home'
    },
    {
      key: navRoutes.doctors.key,
      icon: <ProfileFilled />,
      label: 'Doctors'
    },
    {
      key: navRoutes.reception.key,
      icon: <ReconciliationFilled />,
      label: 'Reception'
    },
    {
      key: navRoutes.logout.key,
      icon: <LogoutOutlined />,
      label: 'Logout'
    }
  ];

  useEffect(() => {
    let location = window.location.pathname;
    let configs = Object.values(navRoutes);
    let selectedRoute = configs.find(item => (item.url === location) || (item.url + '/' === location));

    setDefaultKey(selectedRoute.key);
  }, []);

  const onClickMenuItem = (item) => {
    switch(item.key) {
      case navRoutes.home.key:
        navigate(navRoutes.home.url);
        break;
      case navRoutes.doctors.key:
        navigate(navRoutes.doctors.url);
        break;
      case navRoutes.reception.key:
        navigate(navRoutes.reception.url);
        break;
      case navRoutes.logout.key:
        logOut();
        break;
      default: 
        console.warn('nothing handled!');
    }
  }
  
  return !isLoggedIn ? <Login /> : (
    <div style={{maxHeight: '100vh'}}>
        <div className='flex'>
          <nav className='bg-purple-100' style={{height: '100vh', maxHeight: '100vh', overflowY: 'scroll'}}>
              <img className='m-4' src={logo} width={50} alt={'OBN'}/>
              <div className='text-center'>
                <Button
                  onClick={() => setCollapsed((old => !old))}
                  style={{
                    marginBottom: 16,
                  }}
                >
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
              </div>
              <Menu
                mode="inline"
                defaultSelectedKeys={[defaultKey]}
                defaultOpenKeys={['sub1']}
                style={{
                  backgroundColor: 'transparent',
                  borderRight: 0,
                }}
                className='h-full px-3'
                items={navs}
                inlineCollapsed={collapsed}
                onClick={onClickMenuItem}
              />
              
          </nav>
          <div className='grow' style={{height: '100vh', maxHeight: '100vh', overflowY: 'scroll'}}>
            <Content>
                <Header className='app-back-color'></Header>
                  <div><Outlet /></div>
            </Content>
          </div>
        </div>
    </div>
  )
}

export default AdminRoot