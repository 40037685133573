import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_ROUTE } from "../../utils/commonConstants";
import { GET_ALL } from "../../utils/requestActionConstants";

export const commonStateSlice = createSlice({
  name: "commonState",
  initialState: {
    production: true,
    showTransactionSummary: true,
    globalLoading: false,
    patientsDetails: [],
    hospitalFee: 1500,
    labList: [],
    doctorList: [],
    labServiceList: [],
    drugList: [],
    allDataSavedSuccess: false,
    sideNavRoutes: [
      {
        id: 4,
        label: "Doctors",
        route: "/admin/doctors",
        activeClass: "",
        icon: "bi bi-person-rolodex",
      },
      {
        id: 12,
        label: "Patients",
        route: "/admin/patients",
        activeClass: "",
        icon: "bi bi-person-heart",
      },
      // {
      //   id: 13,
      //   label: "Scans",
      //   route: "/admin/scans",
      //   activeClass: "",
      //   icon: "bi bi-upc-scan",
      // },
      {
        id: 5,
        label: "Labs",
        route: "/admin/labs",
        activeClass: "",
        icon: "bi bi-prescription2",
      },
      {
        id: 6,
        label: "Lab Tests",
        route: "/admin/labTests",
        activeClass: "",
        icon: "bi bi-search-heart",
      },
      // {
      //   id: 14,
      //   label: "Pharmacy",
      //   route: "/admin/pharmacy",
      //   activeClass: "",
      //   icon: "bi bi-hospital",
      // },
      {
        id: 2,
        label: "Drugs",
        route: "/admin/drugs",
        activeClass: "",
        icon: "bi bi-capsule",
      },
      {
        id: 3,
        label: "Suppliers",
        route: "/admin/suppliers",
        activeClass: "",
        icon: "bi bi-layers-fill",
      },
      {
        id: 7,
        label: "Procedures",
        route: "/admin/procedures",
        activeClass: "",
        icon: "bi bi-capsule-pill",
      },
      // {
      //   id: 15,
      //   label: "Home Visits",
      //   route: "/admin/homeVisits",
      //   activeClass: "",
      //   icon: "bi bi-house",
      // },
      {
        id: 11,
        label: "Receipts",
        route: "/admin/receipts",
        activeClass: "",
        icon: "bi bi-receipt",
      },
      {
        id: 100,
        label: "Reports",
        route: "/admin/reports",
        activeClass: "",
        icon: "bi bi-file-bar-graph-fill",
      },
      {
        id: 8,
        label: "Accounts",
        route: "/admin/accounts",
        activeClass: "",
        icon: "bi bi-wallet-fill",
      },
      {
        id: 9,
        label: "Bank Accounts",
        route: "/admin/bankAccounts",
        activeClass: "",
        icon: "bi bi-bank2",
      },
      {
        id: 10,
        label: "Oberon Institute",
        route: "/admin/institute",
        activeClass: "",
        icon: "bi bi-mortarboard-fill",
      },
    ],
    weekDays: [
      { id: 0, weekDayName: "Select" },
      { id: 1, weekDayName: "Monday" },
      { id: 2, weekDayName: "Tuesday" },
      { id: 3, weekDayName: "Wednesday" },
      { id: 4, weekDayName: "Thursday" },
      { id: 5, weekDayName: "Friday" },
      { id: 6, weekDayName: "Saturday" },
      { id: 7, weekDayName: "Sunday" },
    ],
    scanTypeList: [],
    bankAccounts: [],
    employeeList: [],
  },
  reducers: {
    updateSiteEnvironment: (state, action) => {
      state.production = action.payload;
    },

    updateShowTransactionSummary: (state, action) => {
      state.showTransactionSummary = action.payload;
    },

    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },

    setPatientsDetails: (state, action) => {
      state.patientsDetails = action.payload;
    },

    setHospitalFee: (state, action) => {
      state.hospitalFee = action.payload;
    },

    setLabList: (state, action) => {
      state.labList = action.payload;
    },

    setDoctorList: (state, action) => {
      state.doctorList = action.payload;
    },

    setLabServiceList: (state, action) => {
      state.labServiceList = action.payload;
    },

    setDrugList: (state, action) => {
      state.drugList = action.payload;
    },

    setProcedureList: (state, action) => {
      state.procedureList = action.payload;
    },

    setSideNavRoutes: (state, action) => {
      state.sideNavRoutes = action.payload;
    },

    setAllDataSavedSuccess: (state, action) => {
      state.allDataSavedSuccess = action.payload;
    },

    setScanTypeList: (state, action) => {
      state.scanTypeList = action.payload;
    },

    setBankAccounts: (state, action) => {
      state.bankAccounts = action.payload;
    },

    setEmployeeList: (state, action) => {
      state.employeeList = action.payload;
    },
  },
});

export const {
  updateSiteEnvironment,
  updateShowTransactionSummary,
  setGlobalLoading,
  setPatientsDetails,
  setLabList,
  setDoctorList,
  setLabServiceList,
  setDrugList,
  setProcedureList,
  setSideNavRoutes,
  setAllDataSavedSuccess,
  setScanTypeList,
  setBankAccounts,
  setEmployeeList,
} = commonStateSlice.actions;

export const selectProduction = (state) => state.commonState.production;

export const reloadCommonState = () => async (dispatch) => {
  // dispatch(setGlobalLoading(true));
  const labData = await axios.post(API_ROUTE + "/lab", { action: GET_ALL });
  const updatedLabData = labData.data.data.map((dt) => ({
    ...dt,
    label: dt.labName,
  }));

  const docData = await axios.post(API_ROUTE + "/doctor", { action: GET_ALL });

  const labServiceList = await axios.post(API_ROUTE + "/labServiceList", {
    action: GET_ALL,
  });

  const updatedLabServiceListData = labServiceList.data.data.map((dt) => ({
    ...dt,
    label: dt.serviceName + " (" + dt.labName + ")",
  }));

  const drugData = await axios.post(API_ROUTE + "/drug", { action: GET_ALL });
  const updatedDrugData = drugData.data.data.map((dt) => ({
    ...dt,
  }));

  const procedureData = await axios.post(API_ROUTE + "/procedureServiceList", {
    action: GET_ALL,
  });

  const updatedProcedureData = procedureData.data.data.map((dt) => ({
    ...dt,
    label: dt.procedureName,
  }));

  const scanTypeData = await axios.post(API_ROUTE + "/scanTypeList", {
    action: GET_ALL,
  });

  const updatedScanType = scanTypeData.data.data.map((dt) => ({
    ...dt,
    label: dt.scanName,
  }));

  const bankAccountsData = await axios.post(API_ROUTE + "/bankAccount", {
    action: GET_ALL,
  });

  const updatedBankAccounts = bankAccountsData.data.data.map((dt) => ({
    ...dt,
    label: dt.accName,
  }));

  const employeeListData = await axios.post(API_ROUTE + "/employee", {
    action: GET_ALL,
  });

  const updatedEmployeeListData = employeeListData.data.data.map((dt) => ({
    ...dt,
    label: dt.fullName,
  }));

  dispatch(setBankAccounts(updatedBankAccounts));
  dispatch(setLabServiceList(updatedLabServiceListData));
  dispatch(setDoctorList(docData.data.data));
  dispatch(setLabList(updatedLabData));
  dispatch(setDrugList(updatedDrugData));
  dispatch(setProcedureList(updatedProcedureData));
  dispatch(setScanTypeList(updatedScanType));
  dispatch(setEmployeeList(updatedEmployeeListData));
  // dispatch(setGlobalLoading(false));
};

export default commonStateSlice.reducer;
