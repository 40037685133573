import React, { Fragment, useRef } from "react";

function MessageContainer(props) {
  return (
    <Fragment>
      <div className="md:px-10 px-5">
        {props.isImageLeft ? (
          <div className="grid md:grid-cols-2 gap-4 py-5 border-b">
            <div style={{ minHeight: 100 }}>
              <div
                style={{
                  paddingTop: 50,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <div>
                  <div
                    style={{
                      borderRadius: 10,
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={props.image}
                      alt={`${props.image}`}
                      width={700}
                      height={700}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style={{ paddingTop: 50 }}>
                <div>
                  <div className="text-3xl">{props.title}</div>
                  <p className="text-lg border-b py-3"> {props.content}</p>
                  <div className="text-sm pt-5">{props.description}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid md:grid-cols-2 gap-4 py-5 border-b">
            <div>
              <div style={{ paddingTop: 50 }}>
                <div>
                  <div className="text-3xl">{props.title}</div>
                  <p className="text-lg border-b py-3"> {props.content}</p>
                  <div className="text-sm pt-5">{props.description}</div>
                </div>
              </div>
            </div>
            <div sm={6} style={{ minHeight: 100 }}>
              <div
                style={{
                  paddingTop: 50,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <div>
                  <div
                    style={{ borderRadius: 10, overflow: "hidden" }}
                    elevation={2}
                  >
                    <img
                      src={props.image}
                      alt={`${props.image}`}
                      width={700}
                      height={700}
                    />
                  </div>
                </div>
                {/* <div ref={triggerRef} /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default MessageContainer;
