import React, { useState } from 'react';
import { Alert, Button, Form, Input } from 'antd';
import useAuth from '../../../hooks/useAuth';

const LoginForm = () => { 
    const {login} = useAuth();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onFinish = async ({username, password}) => {
       let result = await login(username, password);

       if (result) {
        setIsError(true);
        setErrorMessage(result);
       }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onClose = (e) => {
        setIsError(false);
    };

    return (
    <div>
        <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 16,
            }}
            style={{
            maxWidth: 600,
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='m-auto'
        >
            <Form.Item
                label="Username"
                name="username"
                initialValue={''}
                rules={[
                    {
                    required: true,
                    message: 'Please input your username!',
                    },
                ]}
            >
            <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                initialValue={''}
                rules={[
                    {
                    required: true,
                    message: 'Please input your password!',
                    },
                ]}
            >
            <Input.Password />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 4,
                    span: 16,
                }}
            >
            <Button type='primary' htmlType="submit">Submit</Button>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 4,
                    span: 16,
                }}
            >
                {isError ? 
                    <Alert
                        message={errorMessage}
                        type="warning"
                        closable
                        afterClose={onClose}
                    />
                : '' }
            </Form.Item>
        </Form>   
        
    </div>
  );
}

export default LoginForm;