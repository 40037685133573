import React from 'react'
import RootHeader from '../components/RootHeader';
import RootFooter from '../components/RootFooter';

function NotFound(props) {
    return (
        <div className={''}>
            <RootHeader />
                <div className='py-20 text-2xl text-center my-20'>
                    Ooops... Page Not Found!
                </div>
            <RootFooter />
        </div>
    )
}

export default NotFound;