import axios from "axios";
import {GET, POST} from "../utils/requestActionConstants";

export default async function processReq(method, url, data) {
    const token = sessionStorage.getItem('token');
    let response = {data: null, error: true}
    let headerConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    // console.log(headerConfig);

    switch (method) {
        case GET:
            try {
                let result = await axios.get(url, headerConfig);

                response = result.data;
            } catch (error) {

                // console.log('error printing', error.toString());
                // alert(error.toString());
                response = false;
            }
            
            break;
        case POST:
            try {
                let result = await axios.post(url, data, headerConfig);

                response = result.data;
            } catch (error) {
                // console.log('error printing', error.toString());
                // alert(error.toString());
                // console.log(error.toString())
                response.message = error.response ? error.response.data.message || error.toString() : 'Sorry! Blocked by the server';

                // window.location.replace('/');
            }

            break;
        default:
            response = {};
    }

    return response;
}
