import React, {useEffect, useState} from "react";
import LoginForm from "./components/LoginForm";
import RootHeader from "../../components/RootHeader";
import RootFooter from "../../components/RootFooter";

const Login = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div> 
            <RootHeader />
                <div className='py-10 md:px-20 px-5'>
                    <div className='text-3xl text-center py-10'>Login!</div>
                    <LoginForm />
                </div>
            <RootFooter />
        </div>
    )
}

export default Login;