import React, { useEffect, useState } from 'react'
import processReq from '../../helpers/processReq'
import { GET } from '../../utils/requestActionConstants';
import { API_ROUTE } from '../../utils/commonConstants';
import DataTable from '../../components/DataTable';

function Doctors() {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadDoctors();
  }, [])

  const loadDoctors = async () => {
    const response = await processReq(GET, API_ROUTE + '/auth/doctor');

    setDoctors(response);
    setLoading(false);
  }

  return (
    <div>
      <DataTable 
        columns={[
          "id",
          "fullName",
          "label",
          "telephone",
          "doctorFee",
          "procedureFee"
        ]}
        data={doctors}
        isLoading={loading}
      />
    </div>
  )
}

export default Doctors