import React from 'react'
import {Outlet} from "react-router-dom";
import RootHeader from '../components/RootHeader';
import RootFooter from '../components/RootFooter';

function Root(props) {
    return (
        <div className={''}>
            <RootHeader />
            <Outlet />
            <RootFooter />
        </div>
    )
}

export default Root;