export const POST = "POST";
export const GET = "GET";

export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const ADD_NEW = "ADD_NEW";
export const UPDATE_RECORD = "UPDATE_RECORD";
export const GET_SINGLE = "GET_SINGLE";
export const GET_ALL = "GET_ALL";
export const GET_ALL_BY_ID = "GET_ALL_BY_ID";
export const DELETE = "DELETE";
export const GET_PATIENT_BY_MOBILE = "GET_PATIENT_BY_MOBILE";

export const UPDATE_BALANCE_TO_BE_PAID = "UPDATE_BALANCE_TO_BE_PAID";

// Lab Service Constants
export const UPDATE_ROUTED_DOCTOR = "UPDATE_ROUTED_DOCTOR";
export const GET_MULTIPLE_BY_SERVICE_ID = "GET_MULTIPLE_BY_SERVICE_ID";

export const DEDUCT_PAYMENT = "DEDUCT_PAYMENT";

// Accounts and Reporting
export const GET_SUMMARY_TODAY = "GET_SUMMARY_TODAY";
export const GET_ALL_RECEIPTS = "GET_ALL_RECEIPTS";

// Invoice
export const UPDATE_RECORD_WITH_INVOICE = "UPDATE_RECORD_WITH_INVOICE";

// Procedure Service Constants
export const UPDATE_PROCEDURE_DOCTOR = "UPDATE_PROCEDURE_DOCTOR";

// Scan Service
export const GET_SINGLE_BY_SERVICE_ID = "GET_SINGLE_BY_SERVICE_ID";

// Home Visit Service
export const UPDATE_HOME_VISIT_DOCTOR = "UPDATE_HOME_VISIT_DOCTOR";
export const UPDATE_HOME_VISIT_DESCRIPTION = "UPDATE_HOME_VISIT_DESCRIPTION";
