import React from 'react'

function Academy() {
  return (
    <div className='p-20 text-3xl text-center'>
        Under Construction!
    </div>
  )
}

export default Academy