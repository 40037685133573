import { Button } from "antd";
import { Outlet, useNavigate } from "react-router-dom"
import Login from "../login/Login";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

const ReceptionRoot = () => {
    const {isLoggedIn} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return !isLoggedIn ? <Login /> : (
        <div>
            <div className="text-right">
                <Button onClick={() => navigate('/reception')}>New Token</Button>
                <Button onClick={() => navigate('token')}>Current Token</Button>
                <Button onClick={() => navigate('history')}>History</Button>
                <Button onClick={() => navigate('/admin')}>Admin</Button>
            </div>
            <Outlet />
        </div>
    )
}

export default ReceptionRoot;