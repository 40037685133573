import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { doctors } from "../../dummyData";
import { API_ROUTE } from "../../utils/commonConstants";
import { GET_ALL } from "../../utils/requestActionConstants";

const init = {
  doctors: doctors,
};

export const doctorSlice = createSlice({
  name: "doctorToken",
  initialState: init,
  reducers: {
    setDoctors: (state, action) => {
      state.doctors = action.payload;
    },

    clearData: (state) => {
      return init;
    },
  },
});

export const { clearData, setDoctors } = doctorSlice.actions;

export const loadDoctorsFromDb = () => (dispatch) => {
  axios
    .post(API_ROUTE + "/doctor", {
      action: GET_ALL,
    })
    .then((response) => {
      dispatch(setDoctors(response.data.data));
      // console.log(response.data.data);
    })
    .catch((error) => {
      console.log(error.response.data);
    });
};

export default doctorSlice.reducer;
